import React from "react"
import { graphql } from "gatsby";

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import { formatPrice } from "../../utilities/utils";

const FreeShippingPage = ({data}) => {
    const surcharges = data.states.edges.filter(s => s.charge.surcharge && s.charge.surcharge > 0);

	return (
		<SupportPage page="freeshipping" title="Free Shipping in Continental USA">
    		<PageHeader title="Shipping Policy" />

            <h3>How FAST will my sign arrive?</h3>
            <p>
                Orders take up to 5 days to process and fabricate.  All signs have the option to be shipped using UPS Standard Ground 
                shipping.  Shipping this method usually takes 2 to 4 business days, after fabrication, to arrive.  We can expedite the 
                shipping, but additional charges will apply. If you choose to expedite your shipping, you must choose this option at 
                checkout and you will be notified of the different available options and rates at that time.
            </p>

            <h3>Delivery Method</h3>
            <p>
                Most EZLit® Channel Letters ship using UPS.  Parts such as LEDs, Power Supplies and Fastening clips are also shipped 
                UPS. All Raceway Mounted Letters, Lit Shape signs and Lit Cabinets will ship truck freight. EZLit® Channel Letters that 
                are too large to ship UPS must also ship truck freight. If you have any of these products in your order, and you cannot 
                receive mail through UPS or truck freight to your shipping address, please call us before placing your order so that we 
                can make sure your entire order is shipped correctly.
            </p>

            <h3>Tracking Your Order</h3>
            <p>
                Once you place your order, you will receive an email that links you to a page where you can track the status of your 
                order. You will be given a tracking number for the shipping carrier.
            </p>

			<PageHeader title="Free Shipping Rules" />

            <h3>Shipping Area and International</h3>
            <p>
                Currently, we can only ship to the contiguous United States, Alaska, Hawaii &amp; military APO/FPO addresses 
                must contact us for shipping prices.
            </p>
            <h3>No Inside Deliveries</h3>
            <p>
                Our Freight deliveries are to customers exterior of your store or business location. Our freight products are 
                packaged in a strong crate that can be dragged along the ground to installation location
            </p>

            <h3>Shipping Destination</h3>
            <p>
                Free shipping to Commercial Addresses only. We do not ship to post office boxes or residential addresses. 
                Military Addresses may have additional charges.
            </p>
            <p>
                You can not ship to multiple addresses in the same order.
            </p>

            <h3>Express Shipping</h3>
            <p>
                Free shipping includes standard shipping as defined by SignMonkey.com. Express shipping is not included in our 
                Free Shipping. If you want or need express shipping, you must call and speak to our shipping department to 
                arrange price and delivery dates. We cannot be responsible for the shipping companies delivery times or delays.
            </p>
            <p>
                We would recommend express production, if you need to receive your product quickly. We have a flat fee for 
                express manufacturing from our standard 5 days to 3 days. Check website for current express manufacturing 
                pricing.
            </p>

            <h3>Receiving</h3>
            <p>If requested the Freight company will contact you and set up a delivery time. When they deliver the sign, be ready for it. If you refuse the delivery you will be responsible to pay additional charges associated with your shipment.</p>
            <p>If the customer fail to meet at the scheduled delivery appointment time the customer will be responsible to pay additional charges associated with your shipment.</p>
            <p>Please report all damages immediately, failing to report on delivery may result in damages not covered by SignMonkey.com.</p>

            <p>
                Our products are packaged to protect the face of the sign. Shipping damage to the back, that does not impact performance, can potentially happen.
                The product will not be replaced due to this small damage.                
            </p>

            <h3>Shipper</h3>
            <p>We currently ship freight with Old Dominion or another freight carrier. This is subject to change at any time.</p>
            <p>We currently ship ground with UPS. This is subject to change at any time.</p>
            <p>We do not ship COD, all purchases are paid in full prior shipping.</p>
            <p>Picking product up at our location in Huntington WV. We do allow customers to pick their product up and will only hold the product for 5 days until storage fees begin. Customer picking up there product are also subject to WV sales tax.</p>

            <h3>No Changes of Address</h3>
            <p>We will confirm your address when you place your order. If that address changes at any time after confirming address, you will be responsible for any charges from changing the shipping location or type.</p>

            <h3>Continental USA</h3>
            <p>Our Free Shipping includes only the Continental USA. We do ship outside the Continental USA, please contact our Shipping department to arrange shipping and determine shipping price.</p>

            <h3>Surcharges</h3>
            <p>Due to additional fees when shipping, we must charge an additional surcharge for the following areas:</p>
            {(surcharges.length > 0) ? 
                <ul>
                {surcharges.map(({charge}) => (
                    <li key={charge.id}>
                        {charge.state}{' '}
                        {formatPrice(charge.surcharge)}
                    </li>
                ))}
                </ul>
            :
                <p>- No surcharge areas at this time.</p>
            }
		</SupportPage>
	)
}

export default FreeShippingPage

export const pageQuery = graphql`
  query {
      states: allMysqlStates {
          edges {
              charge: node {
                  id
                  state
                  surcharge: ship_freight_surcharge
              }
          }
      }
  }
`